/* *****MAIN***** */
main {
  width: 100%;
  text-align: center;
  position: relative;
  .main-img {
    position: relative;
  }
}

@keyframes animation1 {
  0% {
    object-position: 30%;
  }
  50% {
    object-position: 80%;
  }

  100% {
    object-position: 30%;
  }
}

.main-img img {
  animation: animation1 20s linear infinite;
  width: 100%;
  height: 500px;
  background-color: #333;
  position: relative;
  object-fit: cover;
  overflow: hidden;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
  z-index: 1;
}

.main-img::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #050000a3;
  width: 100%;
  height: 500px;
  z-index: 2;
  border-bottom-left-radius: 50% 25%;
  border-bottom-right-radius: 50% 25%;
}

@keyframes animation2 {
  0% {
    left: 0;
    opacity: 0.8;
  }
  100% {
    position: absolute;
    bottom: 15%;
    width: 60%;
    left: 20%;
    opacity: 1;
    z-index: 4;
  }
}

.main-dusa {
  animation: animation2 1.6s ease forwards;
  h2 {
    color: #fff;
    font-size: 2.2rem;
    font-weight: 500;
  }
  p {
    font-size: 2rem;
    font-weight: 100;
    color: #fff;
  }
}

/* *****FORM***** */

#form {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 15px auto;
  label {
    font-size: 1.8rem;
    color: $sec-color;
    margin-top: 10px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  input,
  textarea,
  select {
    padding: 15px 15px;
    border-radius: 30px;
    font-size: 1.6rem;
    background-color: #c4c9c76e;
    border: none;
    resize: none;
    &::placeholder {
      font-weight: 500;
      font-size: 1.6rem;
      color: $sec-color;
    }
  }
  input:last-child {
    font-size: 1.6rem;
    margin-top: 20px;
    background-color: $main-color;
    font-weight: 500;
    border: none;
    &:hover {
      background-color: #4db991f3;
      color: $white-text;
    }
    &:active {
      background-color: #398b6d;
    }
  }
}

/* *****OPEN IMAGES ON CLICK***** */

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: #222222ea;
  align-items: center;
  z-index: 1000;
}

#popup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  border-radius: 20px;
  overflow: hidden;

  position: relative;
  img {
    border-radius: 15px;
  }
}

#close {
  position: absolute;
  top: 5px;
  color: #fff;
  font-size: 4rem;
  right: 20px;
  cursor: pointer;
}

/* -----DONACIJE BUTTON----- */

@keyframes donacije {
  0% {
    box-shadow: 0px 0px 2px 5px #3c9a786a;
  }
  50% {
    box-shadow: 0px 0px 0px 0px;
  }
  100% {
    box-shadow: 0px 0px 2px 5px #3c9a786a;
  }
}

.donacija-btn {
  position: fixed;
  right: -40px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  z-index: 10;
  font-size: 1.8rem;
  animation: donacije linear 1.5s infinite;
  color: $white-text;
  transform: all 0.3s;
  font-weight: 500;
  background-color: $main-color;
  padding: 5px 10px;
  border-radius: 15px 10px 5px 15px;
  &:hover {
    opacity: 0.8;
  }
}

/* **********MEDIA QUERIES********** */

/* **********TABLET********** */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
  /* *****OPEN IMAGES ON CLICK***** */
  #popup {
    width: 70%;
  }
}

/* **********DESKTOP********** */
@media only screen and (min-width: 1024px) {
  /* *****MAIN***** */
  main {
    width: 100%;
    margin: 0 0%;
  }
  .main-img img {
    border-bottom-left-radius: 45%;
    border-bottom-right-radius: 45%;
  }

  .main-img::after {
    border-bottom-left-radius: 45%;
    border-bottom-right-radius: 45%;
  }
  /* *****OPEN IMAGES ON CLICK***** */

  #popup {
    width: 50%;
  }
}
