.section {
  width: 100%;
  padding: 20px;
  @include page-width;
  h2 {
    @include title-h2;
    font-size: 3rem;
  }
  h3 {
    font-size: 2.6rem;
  }
  iframe {
    width: 90%;
    height: 300px;
    margin: 10px auto;
    border-radius: 15px;
  }
}

.contact-link {
  @include flex;
  flex-direction: column;
  width: 80%;
  margin: 5px 10%;
  padding: 10px 0;
  text-align: $text-centar;
  h4 {
    font-size: 2.2rem;
    font-weight: 600;
    color: $main-color;
  }
  p {
    @include paragraph;
  }

  i {
    font-size: 2rem;
    color: $main-color;
  }
  a {
    font-size: 2rem;
    color: #333;
    span {
      font-size: 2.2rem;
      font-weight: 500;
      color: #333;
    }
  }
}

/*  */

.raspored-misa {
  width: 80%;
  margin: 15px 10%;
  text-align: $text-centar;
  h3 {
    font-size: 2.2rem;
    color: #3c9a78;
    border-left: 5px solid;
    padding-left: 10px;
    width: fit-content;
    margin: 0 auto;
  }
  div h5 {
    margin-top: 15px;
    font-size: 2.2rem;
    font-weight: 600;

    span {
      font-size: 1.8rem;
      color: #3c9a78;
    }
  }
  div p {
    margin: 5px auto;
    font-size: 2.2rem;
    font-weight: 500;
    color: #555;
    span {
      font-size: 2rem;
      color: #3c9a78;
      font-weight: 500;
    }
  }
}

/* -----MEDIA QUERIES----- */

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
  .contact-link {
    h4 {
      font-size: 2.4rem;
    }

    i {
      font-size: 2.2rem;
    }
    a {
      font-size: 2.2rem;
      span {
        font-size: 2.4rem;
      }
    }
  }

  /*  */

  .raspored-misa {
    h3 {
      font-size: 2.4rem;
    }
    div h5 {
      font-size: 2.4rem;
      span {
        font-size: 2rem;
      }
    }
    div p {
      font-size: 2.4rem;
      span {
        font-size: 2.2rem;
      }
    }
  }
}
