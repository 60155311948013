.nakane-table {
  width: 90%;
  margin: 10px auto;
  overflow: hidden;
  border: 2px solid $main-color;
  border-radius: 15px;
  background-color: #bfc7c434;
  .nakane-day {
    padding: 10px 5px;
    border-bottom: 1px solid $main-color;
    border-right: 1px solid $main-color;
    h4 {
      font-size: 2rem;
    }
    span {
      font-size: 1.6rem;
      color: $main-color;
      font-weight: 600;
    }
  }
  .nakane-rows {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;

    .nakane-grid {
      display: grid;
      grid-template-columns: 1fr 3fr;

      h6 {
        font-size: 1.6rem;
        padding: 10px 0;
        border-right: 1px solid $main-color;
        border-bottom: 1px solid $main-color;
      }
    }
    .nakane-names {
      width: 100%;
      border-bottom: 1px solid $main-color;
      padding: 5px;
      p {
        text-align: left;
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
}

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
}
