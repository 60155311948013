.salezijanci-lenta {
  width: 90%;
  margin: 0px 5%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 20px 0;
  .salezijanci-box {
    h5 {
      margin-left: 20px;
      border-left: 3px solid $main-color;
      padding-left: 5px;
      text-align: left;
      font-size: 2rem;
      span {
        font-size: 1.6rem;
      }
    }
    p {
      padding: 15px 0px 15px 30px;

      font-size: 1.6rem;
      text-align: left;
      border-left: 2px dashed $main-color;
    }
  }
}

/* -----MEDIA QUERIES----- */

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
}

/* DESKTOP */
@media only screen and (min-width: 1024px) {
}
