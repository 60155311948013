footer {
  width: 100%;
  padding: 15px 0px 10px;
  background-color: $main-color;
  text-align: $text-centar;
  border-top-left-radius: 25% 10%;
  border-top-right-radius: 25% 10%;
  .footer-links,
  .footer-contact {
    width: 80%;
    padding: 10px;
    margin: 5px auto;
    h3 {
      font-size: 2.4rem;
      color: #333;
      margin: 10px 0px;
    }
    &:last-child {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    a {
      color: #fff;
      font-weight: 600;
      font-size: 2rem;
      i {
        font-size: 2.4rem;

        margin: 5px 10px;
        color: #fff;
        font-weight: 400;
      }
    }
  }
  p {
    text-align: center;
    font-weight: 500;
    font-size: 1.8rem;
    margin: 40px 0 15px;
    h4 {
      font-size: 1.8rem;
      display: inline-block;
      font-weight: 500;
    }
  }
}

.footer-contact a {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.8rem;
  color: $white-text;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 20px;
}

.footer-border {
  width: 40%;
  height: 1px;
  background-color: $footer-border;
  margin: 10px auto;
  &:last-of-type {
    margin: 25px auto 5px;
    width: 100%;
    background-color: $sec-color;
  }
}

/* -----MEDIA QUERIES----- */

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
  footer {
    .footer-links,
    .footer-contact {
      width: 80%;
      padding: 10px;
      margin: 5px auto;
      h3 {
        font-size: 2.6rem;
      }

      a {
        font-size: 2.2rem;
        i {
          font-size: 3rem;

          margin: 5px 10px;
          color: #fff;
          font-weight: 400;
        }
      }
    }
    p {
      text-align: center;
      font-weight: 500;
      font-size: 1.8rem;
      margin: 40px 0 15px;
    }
  }

  .footer-contact a {
    font-size: 2rem;
    color: $white-text;
  }

  .footer-border {
    width: 20%;
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  footer {
    border-top-left-radius: 25% 25%;
    border-top-right-radius: 25% 25%;

    .footer-links,
    .footer-contact {
      h3 {
        font-size: 2.6rem;
        color: #333;
        margin: 10px 0px;
      }
      a {
        font-size: 2.2rem;
        i {
          font-size: 2.6rem;
          transition: all 0.5s;
          &:hover {
            color: $sec-color;
          }
          &:active {
            color: $gray-text;
          }
        }
        &:hover {
          color: $sec-color;
        }
        &:active {
          color: $gray-text;
        }
      }
    }
    p {
      font-size: 2rem;
      h4 {
        font-size: 2rem;
      }
    }
  }

  .footer-contact a {
    font-size: 2rem;
    &:hover {
      color: $sec-color;
    }
    &:active {
      color: $gray-text;
    }
  }
}
