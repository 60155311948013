.pastoralni-box {
  width: 100%;
  margin: 5px auto;
  padding: 10px 20px;
  img {
    width: 80%;
    border-radius: 15px;
    margin: 10px auto;
  }
  p {
    @include paragraph;
  }
}

#donation {
  color: $white-text;
  background-color: $sec-color;
  font-weight: 500;
  font-size: 2rem;
  border-radius: 50px;
  padding: 10px 15px;
  border: none;
}

/* -----MEDIA QUERIES----- */

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
  .pastoralni-box {
    width: 100%;

    img {
      width: 80%;
    }
  }

  #donation {
    font-size: 2.6rem;
  }
}
@media only screen and (min-width: 1023px) {
  .pastoralni-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .pastoralni-box {
      img {
        width: 100%;
      }
      p {
        @include paragraph;
      }
    }
  }
}
