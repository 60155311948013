.zupa-buttons {
  position: sticky;
  top: 10vh;
  display: flex;
  flex-grow: 1;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 0 20px;
  margin-top: 15px;
  a {
    margin: 0 2px;
    flex-grow: 1;
    font-size: 1.6rem;
    padding: 5px 10px;
    background-color: $main-color;
    color: $white-text;
    border-radius: 30px;
    font-weight: 500;
    transition: all 0.5s;
    &:hover {
      background-color: $main-color;
      color: $sec-color;
      font-weight: 500;
    }
  }
}

.povijest-box {
  margin: 20px 0;
  p {
    text-align: left;
    margin: 10px 0;
    font-size: 1.8rem !important;
    padding: 5px 0 5px 20px;
    border-radius: 15px;
  }
}

.zupa-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 5px;
  margin: 10px 0;
  .zupa-box {
    display: flex;
    h5 {
      display: flex;
      font-size: 2rem;
      color: $main-color;
      padding: 6px;
    }
    p {
      width: fit-content !important;
      font-size: 1.8rem;
      margin: 7px 0 5px 10px;
    }
  }
  p {
    margin: 10px 0;
    font-size: 1.8rem !important;
    text-align: left;
  }
  ul {
    margin: 10px 0;
    list-style: none;
    text-align: left;
    li {
      display: flex;

      div {
        margin-top: 6px;
        margin-left: 10px;
        width: 80%;
        font-size: 1.8rem !important;
        color: #555 !important;
      }
    }
  }
}

.animatori-grid {
  img {
    width: 100%;
    margin: 15px 0;
    background-color: #3c9a78;
    border-top-left-radius: 15% 35%;
    border-bottom-left-radius: 15% 35%;
    border-top-right-radius: 15% 35%;
    border-bottom-right-radius: 15% 35%;
  }
}

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  .zupa-buttons {
    width: 50%;
    margin: 15px auto 0;
    a {
      &:hover {
        background-color: $sec-color;
        color: $white-text;
      }
    }
  }
}
