#navbar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  backdrop-filter: blur(1px);
  justify-content: space-between;
  align-items: center;
  height: 8vh;
  padding: 30px 20px;
  z-index: 10;
  transition: all 0.5s;
  h1 {
    font-size: 2.2rem;
    color: $white-text;
    font-weight: 800;
    &:hover {
      opacity: 0.8;
    }
  }
  #nav-logo {
    width: 30px;
    z-index: 200;
    transition: src 0.5s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

.donacija-btn h2 {
  display: none;
}

.nav-links {
  list-style: none;
  li {
    margin: 8px 0;
    a {
      color: #fff;
      font-size: 2.5rem;
      font-weight: 600;
      list-style: none;
    }
  }
}
.submenu {
  display: none;
  list-style: none;
  li {
    a {
      font-size: 1.6rem !important;
      font-weight: 500 !important;
    }
  }
}

.nav-links li:hover .submenu {
  display: block;
}

.link-border {
  height: 1px;
  width: 20%;
  margin: 5px auto;
  background-color: #ffffff40;
}

.hamburger {
  display: none;
  cursor: pointer;
  z-index: 15;
  .bar {
    display: block;
    border-radius: 5px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    /*  */
    &:nth-child(1),
    &:nth-child(3) {
      width: 35px;
      height: 1.5px;
    }
    &:nth-child(2) {
      width: 25px;
      height: 1px;
    }
  }
}

/* **********MEDIA QUERIES********** */

/* -----TABLET & MOBILE NAVBAR----- */
@media only screen and (max-width: 1024px) {
  /* -----NAVBAR----- */
  .hamburger {
    display: block;
    z-index: 100;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-10px) rotate(-45deg);
  }

  .nav-links {
    position: fixed;
    top: -1000%;
    left: 0;
    height: 500px;
    max-height: 500px;
    overflow: hidden;
    flex-direction: column;
    background-color: #3c9a78ec;
    width: 100%;
    box-shadow: 2px 5px 10px #124b3675;
    text-align: center;
    transition: 0.3s;
    padding: 30px 20px;
    z-index: 100;
    border-bottom-left-radius: 50% 15%;
    border-bottom-right-radius: 50% 15%;
    ul {
      margin: 20px 0;
    }
  }

  .nav-links.active {
    top: 0%;
  }

  .social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    a {
      font-size: 5rem;
      color: #fff;
      text-decoration: none;
    }
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  .logo {
    width: 180px;
  }

  #navbar {
    width: 100%;
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 90;
    .social-links,
    h1 {
      display: none;
    }
    .submenu {
      margin-top: 5px;
      padding: 0 10px;
      display: none;
      position: absolute;
      list-style: none;
      /* background: #3c9a78; */
      padding-bottom: 10px;
      border-radius: 0px 5px 15px 25px;
      li {
        margin: 10px 0;
        a {
          border-radius: 10px;
          font-size: 1.6rem;
          font-weight: 600;
          background: #3c9a78;
          margin-bottom: 15px;
          &:hover {
            color: #333 !important;
            font-weight: 700 !important;
          }
        }
      }
    }

    .nav-links li:hover .submenu {
      display: block;
    }
  }

  .nav-links {
    display: flex;
    gap: 5px;
  }

  .nav-links li a {
    padding: 5px 10px;
    transition: ease-in 0.3s;
    font-size: 2rem;
    /* background-color: #3c9a78; */
    /* box-shadow: 0px 0px 10px 5px #33333376; */
    color: $white-text;
    border-radius: 15px 5px 15px 5px;
    &:hover {
      background: #3c9a78;
      color: $sec-color !important;
      border-radius: 15px 5px 15px 5px;
    }
    &:active {
      background-color: $sec-color;
      color: $main-color;
    }
  }

  .link-border {
    display: none;
  }
}
