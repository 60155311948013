#section-donacije {
  p {
    @include paragraph;
  }
  h5 {
    @include title-h5;
    font-size: 2rem;
    color: $main-color;
  }

  @keyframes amount {
    0% {
      width: 0;

      font-size: 0;
    }
    50% {
      font-size: 1;
    }
    100% {
      width: 25%;
      color: $white-text;
      font-size: 1.4rem;
    }
  }

  .bank-amout {
    width: 80%;
    height: 35px;
    border-radius: 30px;
    border: 2px solid $main-color;
    position: relative;
    overflow: hidden;
    margin: 25px auto;
    display: flex;
    align-items: center;
    color: $main-color !important;
    justify-content: flex-end;
    padding-right: 15px;
    font-size: 1.4rem;
    font-weight: 500;
    .amout-line {
      animation: amount 3.5s ease forwards;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 35px;
      background-color: $main-color;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.donacije-box {
  width: 100%;
  position: relative;
  margin: 20px 0;
  padding: 30px 20px 40px;
  text-align: $text-centar;
  h4 {
    font-size: 2rem;
  }
  img {
    width: 50px;
  }
  p {
    color: $white-text !important;
    text-align: left;
    width: 80%;
    margin: 5px auto;
  }
}

.donacije-box::before {
  animation: animationPastoralni 1s ease-in-out infinite;
  overflow: hidden;
  content: "";
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  transform: skewY(3deg);
  background-color: #3c9a78;
  border-top-left-radius: 25% 25%;
  border-top-right-radius: 25% 25%;
  border-bottom-left-radius: 25% 25%;
  border-bottom-right-radius: 25% 25%;
}
