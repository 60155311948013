.thanks {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 1;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(#3c9a78, #2f3231e6);
  }
  .thanks-img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .thanks-box {
    position: absolute;
    text-align: center;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    padding: 15px 25px 30px;
    background: #403a3a50;
    border-radius: 30px;
    img {
      width: 40%;
      margin: 10px auto;
    }
    h2 {
      margin: 5px auto;
      width: fit-content;
      font-size: 3rem;
      color: $main-color;
    }
    p {
      font-size: 1.8rem;
      color: $white-text;
      margin-bottom: 30px;
    }
    a {
      padding: 10px 15px;
      background-color: $main-color;
      font-size: 2rem;
      border-radius: 30px;
      color: $white-text;
    }
  }
}

/* TABLET */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
  .thanks {
    .thanks-box {
      width: 60%;
      padding-bottom: 40px;
    }
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  .thanks {
    .thanks-box {
      width: 35%;
      padding-bottom: 60px;
    }
  }
}
