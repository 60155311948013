// Partials SCSS
@import "./Partials/typography";
@import "./Partials/colors";
@import "./Partials/variables";
@import "./Partials/reset";
@import "./Partials/footer";
@import "./Partials/navigation";
@import "./Partials/main";
// Pages SCSS
@import "./PagesCSS/donacije";
@import "./PagesCSS/donbosco";
@import "./PagesCSS/kontakt";
@import "./PagesCSS/naslovnica";
@import "./PagesCSS/obavijesti";
@import "./PagesCSS/pastoralni-centar";
@import "./PagesCSS/salezijanci";
@import "./PagesCSS/thanks";
@import "./PagesCSS/zajednica";
@import "./PagesCSS/zupa";

/* *****SECTIONS***** */
.section {
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  text-align: center;
  h1 {
    text-align: center;
    font-size: 2.8rem;
    font-family: $main-font;
  }
  h3 {
    font-size: 2rem;
    color: $main-color;
  }
  p {
    width: 90%;
    margin: 5px 5%;
    font-size: 2rem;
    font-weight: 400;
  }
  span {
    font-size: 2.6rem;
    color: $main-color;
  }
}

/* **********MEDIA QUERIES********** */

/* *****TABLET***** */
@media only screen and (min-width: 725px) and (max-width: 1023px) {
  /* *****SECTIONS***** */
  .section {
    width: 80%;
    margin: 10px 10%;

    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2.4rem;
    }
    p {
      font-size: 2rem;
    }
    iframe {
      width: 80%;
      margin: 0 10%;
    }
  }
}

/* *****DESKTOP***** */
@media only screen and (min-width: 1024px) {
  /* *****SECTIONS***** */
  .section {
    width: 60%;
    margin: 10px 20%;
    h1 {
      font-size: 3.2rem;
    }
    h3 {
      font-size: 2.6rem;
    }
    p {
      font-size: 2.2rem;
    }
    iframe {
      width: 70%;
      margin: 0 15%;
      height: 400px;
    }
  }
}
