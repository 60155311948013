* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
  text-decoration: none;
  font-family: $main-font;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  position: relative;
}

@mixin page-width {
  width: 100%;
  padding: 20px;
  text-align: $text-centar;
}

@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.light-border {
  width: 20%;
  margin: 15px 40%;
  height: 1px;
  background-color: #3c9a789b;
}
