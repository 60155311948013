/* *****MISA*****  */

.misa h5 {
  @include title-h5;
  font-family: $main-font;
}

.misa p {
  @include paragraph;
  font-weight: 400;

  &:last-of-type {
    margin-bottom: 20px;
  }
}

/* *****PASTORALNI CENTAR***** */

@keyframes animationPastoralni {
  0% {
    box-shadow: 0px 0px 5px 0px #3c9a7864;
  }
  50% {
    box-shadow: 0px 0px 4px 5px #3eb0866d;
  }
  100% {
    box-shadow: 0px 0px 5px 0px #3c9a7864;
  }
}

#section-pastoralni {
  position: relative;
  width: 100%;
  margin: 10px 0;
  padding: 30px 20px 40px;
  text-align: $text-centar;
}

.pastoralni-video::before {
  animation: animationPastoralni 1s ease-in-out infinite;
  overflow: hidden;
  content: "";
  padding: 10px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -10;
  transform: skewY(3deg);
  background-color: #3c9a78;
  border-top-left-radius: 25% 15%;
  border-top-right-radius: 25% 15%;
  border-bottom-left-radius: 35% 15%;
  border-bottom-right-radius: 25% 15%;
}

#video {
  width: 90%;
  height: 200px;
  margin: 10px auto;
  border-radius: 15px;
  border: 6px solid #222;
}

.pastoralni-text {
  padding: 10px 20px;
  text-align: $text-centar;
  @include paragraph;
  color: $white-text;
}

.pastoralni-video a,
.oratorij-text a {
  font-size: 2rem;
  color: $sec-color;
  font-weight: 600;

  border-radius: 5px;
  margin-top: 15px;
}

/* *****OBAVIJESTI***** */

.obavijesti-box {
  display: none;
  padding: 10px 10px;
  h5 {
    font-size: 1.4rem;
    color: $gray-text;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 2rem;
    color: $main-color;
  }
  &:first-of-type {
    display: block;
  }
  .obavijesti-container {
    overflow: hidden;
    position: relative;
    width: 100%;

    .testimonial {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .slide-row {
        display: flex;
        transition: transform 0.5s ease-in-out;

        .slide-col {
          width: 100%;
          flex: 0 0 auto;
          cursor: pointer;

          .obavijesti-text {
            display: flex;
            justify-content: space-between;
            text-align: left;
            width: 100%;
            border-radius: 100px;
            margin: 10px auto;
            list-style: none;
            background-color: $main-color;
            padding: 20px 20px;
            white-space: nowrap;
            transition: white-space 0.5s;

            p {
              width: 80%;
              font-size: 2rem;
              overflow: hidden;
              color: $white-text;
              text-overflow: ellipsis;
            }

            .arrow-box {
              cursor: pointer;
              display: flex;
              align-items: center;
              margin-left: 10px;

              .arrow {
                display: block;
                border-radius: 5px;
                transition: transform 0.4s;
                background-color: $white-text;

                &:first-child {
                  width: 15px;
                  transform: rotate(45deg) translateX(4px);
                  height: 2.5px;
                }
                &:last-child {
                  width: 15px;
                  transform: rotate(-45deg) translateX(-4px);
                  height: 2.5px;
                }
              }
            }
          }
          .obavijesti-text.active-text {
            max-height: 500px;
            white-space: normal;
            border-radius: 25px;
          }
        }
      }
    }
  }
}

/* *****DOGAĐAJI***** */

#section-novosti {
  padding-bottom: 30px;
}

.novosti-grid {
  width: 100%;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.novosti-border {
  width: 25%;
  margin: 15px auto;
  height: 1px;
  background-color: $main-color;
}

.dogadaji-btn {
  font-size: 1.8rem !important;
  color: $main-color !important;
}

.oratorij-box {
  padding-bottom: 15px;
  h3 span {
    color: #000;
    margin-left: 5px;
  }
  img {
    width: 70%;
    margin: 10px auto;
    border-radius: 15px 10px 5px 15px;
    margin-top: 15px;
    transform: skewY(2deg);
    background-color: #3c9a78;
    border-top-left-radius: 15% 35%;
    border-bottom-left-radius: 35% 25%;
    border-top-right-radius: 25% 35%;
    border-bottom-right-radius: 25% 45%;
    transition: src ease-in 0.5s;
  }
}

.oratorij-text p {
  @include paragraph;
}

.oratorij-text h5 {
  @include title-h5;
}

/* *****BLAGOSLOV OBITELJI***** */

/* -----UKLJUČI SE U ŽUPU----- */
.join-us {
  width: 90%;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  .join-us-box {
    position: relative;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.6rem;
      color: $white-text;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.5s;
      background: linear-gradient(#33c2929a, #2f3231ec);
      &:hover {
        background: linear-gradient(#3fa281a7, #292c2bed);
      }
    }
  }
}

/* -----MEDIA QUERIES----- */

/* ----------TABLET---------- */

@media only screen and (min-width: 725px) and (max-width: 1023px) {
  /* -----PASTORALNI CENTAR----- */

  .pastoralni-video::before {
    animation: animationPastoralni 1s ease-in-out infinite;
    overflow: hidden;
    content: "";
    padding: 10px;
    position: absolute;
    left: 10%;
    right: 10%;
    top: 0;
    bottom: 0;
    z-index: -10;
    transform: skewY(3deg);
    background-color: #3c9a78;
    border-top-left-radius: 25% 25%;
    border-top-right-radius: 25% 25%;
    border-bottom-left-radius: 25% 25%;
    border-bottom-right-radius: 25% 25%;
  }

  #video {
    width: 65%;
    height: 250px;
  }

  .pastoralni-text {
    width: 80% !important;
    margin: 5px auto !important;
  }

  .pastoralni-video a,
  .oratorij-text a {
    font-size: 2.4rem;
  }

  /* -----SECTION OBAVIJESTI----- */

  .obavijesti-box {
    padding: 10px 20px;
    h3 {
      font-size: 2.4rem;
    }
  }

  .obavijesti-box h5 {
    font-size: 1.8rem;
  }

  .obavijesti-text {
    p {
      font-size: 1.8rem;
    }
  }

  /* -----SECTION LJETNI ORATORIJ----- */

  .novosti-grid {
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }

  .novosti-border {
    display: none;
  }
  .dogadaji-btn {
    font-size: 1.4rem;
  }

  .oratorij-box {
    padding-bottom: 15px;
    h3 span {
      color: $main-color;
      font-size: 2.2rem;
    }

    img {
      width: 80%;
      margin: 20px auto;
      border-top-left-radius: 25% 25%;
      border-top-right-radius: 25% 25%;
      border-bottom-left-radius: 25% 25%;
      border-bottom-right-radius: 25% 25%;
    }
  }
}

/* ----------DESKTOP---------- */

@media only screen and (min-width: 1024px) {
  /* -----PASTORALNI CENTAR----- */
  #section-pastoralni {
    width: 70%;
    margin: 10px 15%;
  }

  .pastoralni-video::before {
    left: 10%;
    right: 10%;
    border-top-left-radius: 25% 25%;
    border-top-right-radius: 25% 25%;
    border-bottom-left-radius: 25% 25%;
    border-bottom-right-radius: 25% 25%;
  }

  #video {
    width: 50%;
    height: 300px;
  }
  .pastoralni-text {
    width: 80% !important;
    margin: 5px auto !important;
  }

  .pastoralni-video a,
  .oratorij-text a {
    font-size: 2.4rem;
  }

  .dot.active-dot {
    height: 16px;
    width: 32px;
  }

  .dot {
    height: 16px;
    width: 16px;
  }
  /* -----SECTION OBAVIJESTI----- */

  .obavijesti-box {
    padding: 10px 20px;
    h3 {
      font-size: 2.4rem;
    }
  }

  .obavijesti-box h5 {
    font-size: 1.8rem;
  }

  .obavijesti-text {
    p {
      font-size: 2.2rem;
    }
  }

  /* -----SECTION LJETNI ORATORIJ----- */
  .novosti-grid {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .novosti-border {
    display: none;
  }

  .dogadaji-btn {
    font-size: 1.8rem !important;
  }

  .oratorij-box {
    padding-bottom: 15px;
    h3 span {
      color: $main-color;
      font-size: 2.2rem;
    }

    img {
      width: 60%;
      margin: 20px auto;
      border-top-left-radius: 25% 25%;
      border-top-right-radius: 25% 25%;
      border-bottom-left-radius: 25% 25%;
      border-bottom-right-radius: 25% 25%;
    }
  }
}
