$main-font: "Quicksand", sans-serif;
$secondary-font: "Belanosima", sans-serif;
@import "./colors";

/* Variables */
$text-centar: center;

@mixin title-h1 {
  text-align: $text-centar;
  font-size: 2.8rem;
}

@mixin title-h2 {
  text-align: $text-centar;
  font-size: 2.6rem;
  margin: 10px auto;
}

@mixin title-h3 {
  text-align: $text-centar;
  font-size: 2.2rem;
  color: $main-color;
  border-left: 6px solid;
  width: 80%;
  margin: 0 auto;
}

@mixin title-h5 {
  font-size: 2rem;
  color: $sec-color;
  margin-top: 10px;
  margin-bottom: 5px;
}

@mixin paragraph {
  margin: 5px auto;
  font-size: 2rem;
  font-weight: 500;
  color: $gray-text;
}

@mixin text-span {
  font-size: 2.6rem;
  color: $main-color;
}
